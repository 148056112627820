export default function Error(){
    return (
    <div>

        <h1>Error!</h1>
        <hr/>
        <h3>There has been an error 🫨</h3>

    </div>
    )
}